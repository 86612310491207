@use "../../App";

body {
  @include App.body;
}

.container {
  @include App.container;
  @include App.NormText;
  background-color: #f6f7f9;
  align-items: flex-start;
  flex-direction: row;
  height: 100vh;
}

.rightContainer {
  width: 100%;
  height: 100vh;
  // background-color: pink;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder {
  width: 480px;
  background-color: pink;
  height: 100vh;
  left: 0;
}

.pic {
  // transform: rotate(90deg);
  height: 90vh;
  // height: 0%;
}

.legend {
  position: absolute;
  right: 30px;
  bottom: 90px;
  width: 150px;

  img {
    width: 100%;
  }
}

.compass {
  position: absolute;
  right: 40px;
  bottom: 20px;
  width: 50px;

  img {
    width: 100%;
  }
}
