.App {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
}

.click {
  cursor: pointer;
  margin: 5px 15px;
  text-decoration: underline;
  color: white;
  background-color: transparent;
  border: none;
}

.mobileClick {
  cursor: pointer;
  margin: 5px 15px;
  color: white;
  font-weight: 500;
  width: "100%";
  background-color: transparent;
  border: none;
}

.signinsignup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container2 {
  width: 100%;
  text-align: center;
}

.container2 span {
  font-style: italic;
}

.subsectionM {
  display: flex;
  flex-direction: row;
  padding-right: 15px;
}

input {
  padding: 8px;
  margin: 12px 0;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 70%;
}

.button {
  background-color: teal;
  border: none;
  color: white;
  padding: 12px;
  margin: 12px 0;
  width: 70%;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
}

.container {
  box-shadow: 0 1px 6px #ccc;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  padding: 22px;
  background-color: pink;
}

.container > p {
  cursor: pointer;
  margin: 12px;
}

.form {
  width: 100%;
}

.form > h2 {
  text-align: center;
}

.form > form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form > form > p {
  cursor: pointer;
}

.error {
  background-color: tomato;
  color: white;
  border-radius: 6px;
  padding: 6px 12px;
  font-weight: bold;
  max-width: 400px;
  margin-bottom: 18px;
}
