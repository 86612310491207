@mixin body {
  font-family: Poppins;
  font-family: san-serif;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  height: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Poppins;
    font-family: sans-serif;
  }

  html,
  body,
  div,
  span,
  object,
  iframe,
  p,
  blockquote,
  pre,
  abbr,
  address,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  samp,
  small,
  strong,
  sub,
  sup,
  var,
  b,
  i,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  figure,
  footer,
  header,
  menu,
  nav,
  section,
  menu,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
}

@mixin container {
  // background: $backGround;
  font-family: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  min-height: 100vh;
}

@mixin NormText {
  font-family: Avenir;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #303030;
}

@mixin BoldText {
  font-family: Avenir;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  color: #303030;
}
