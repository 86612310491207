.containerOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  text-align: left;
  font-size: 16px;
  height: calc(100vh - 150px - 75px + 3px);
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
}

.link {
  text-decoration: underline;
  margin-top: 10px;
  color: rgb(105, 118, 128);
  font-size: 14px;
}
