.header {
  left: 0;
  top: 0;
  height: 10vh;
  width: 100vw;
  position: fixed;
  background-color: #2c5a7b;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerR {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.bold {
  font-family: Avenir;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1.3rem;
  color: white;
  margin-left: 20px;
}

.mobileBold {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 1.1rem;
  color: white;
  margin-left: 3rem;
}
