.containerMobile {
  margin-bottom: 20px;
  font-size: 16px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.headerMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 5px;
}

.message {
  margin-bottom: 25px;
}

.messageMobile {
  margin-bottom: 20px;
}
