html {
  height: 100%;
}

.container {
  width: calc(100% - 360pt);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.containerExpandMobile {
  height: calc(100% - 75px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fcfcfc;
}

.containerExpand {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fcfcfc;
}

.select {
  background: #eeeeee;
  border: 1.5px solid #969696;
  border-radius: 23px;
  position: absolute;
  left: 30px;
  bottom: 40px;
  min-height: 45px;
  min-width: 30px;
}

.selectMobile {
  background: #e9e9e9;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  position: absolute;
  left: 12px;
  bottom: 40px;
  min-height: 45px;
  min-width: 30px;
}

.placeholder {
  width: 480px;
  background-color: pink;
  height: 100vh;
  left: 0;
}

.btnMobile {
  background: #ffffff;
  border-radius: 5px;
  width: 23px;
  height: 23px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  font-family: Avenir;
  font-family: sans-serif;
  font-size: 12px;
  color: #303030;
  text-orientation: revert;
}

.btn {
  background: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  font-family: Avenir;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #303030;
  text-orientation: revert;
}

.btnClickedMobile {
  background: #aad0eb;
  border-radius: 5px;
  width: 23px;
  height: 23px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  font-family: Avenir;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #303030;
}

.btnClicked {
  background: #aad0eb;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  font-family: Avenir;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #303030;
}

.pic {
  height: 90vh;
}

.legend {
  position: absolute;
  right: 30px;
  bottom: 90px;
  width: 150px;
}

.legendMobile {
  position: absolute;
  right: 20px;
  bottom: 15px;
  width: 25%;
}

.legend img {
  width: 100%;
}

.compass {
  position: absolute;
  right: 40px;
  bottom: 20px;
  width: 50px;
  display: none;
}

.compass img {
  width: 100%;
}
