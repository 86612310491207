@use "../../App";

body,
html {
  overflow-x: hidden;
}

body {
  @include App.body;
}

.container {
  @include App.container;
  @include App.NormText;
  flex-direction: row;
}

.leftContainer {
  width: 780px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
  z-index: 1;
}

.rightContainer {
  background-color: white;
  height: 100vh;
  width: 100%;
}

.bold {
  @include App.BoldText;
  text-align: center;
  margin-bottom: 25px;
  font-size: 0.9rem;
}

.link {
  margin-top: 25px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
  a {
    text-decoration: underline;
    margin-bottom: 15px;
    font-family: Avenir;
    font-family: sans-serif;
  }
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  font-family: Avenir;
  font-family: sans-serif;
  background-color: #e96a34;
  color: white;
  border-radius: 15px;
  padding: 11px 20px;
  font-size: 0.8rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

@media (max-width: 860px) {
  .container {
    justify-content: center;
    background-color: #f6f7f9;
  }
}
