body {
  font-family: Poppins;
  font-family: san-serif;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  height: 100%;
}
body a {
  color: inherit;
  text-decoration: none;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: Poppins;
  font-family: sans-serif;
}
body html,
body body,
body div,
body span,
body object,
body iframe,
body p,
body blockquote,
body pre,
body abbr,
body address,
body cite,
body code,
body del,
body dfn,
body em,
body img,
body ins,
body kbd,
body q,
body samp,
body small,
body strong,
body sub,
body sup,
body var,
body b,
body i,
body dl,
body dt,
body dd,
body ol,
body ul,
body li,
body fieldset,
body form,
body label,
body legend,
body table,
body caption,
body tbody,
body tfoot,
body thead,
body tr,
body th,
body td,
body article,
body aside,
body figure,
body footer,
body header,
body menu,
body nav,
body section,
body menu,
body time,
body mark,
body audio,
body video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.container {
  font-family: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  font-family: Avenir;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #303030;
  background-color: #f6f7f9;
  align-items: flex-start;
  flex-direction: row;
  height: 100vh;
}

.rightContainer {
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder {
  width: 480px;
  background-color: pink;
  height: 100vh;
  left: 0;
}

.pic {
  height: 90vh;
}

.legend {
  position: absolute;
  right: 30px;
  bottom: 90px;
  width: 150px;
}
.legend img {
  width: 100%;
}

.compass {
  position: absolute;
  right: 40px;
  bottom: 20px;
  width: 50px;
}
.compass img {
  width: 100%;
}/*# sourceMappingURL=latinway.module.css.map */