.container {
  background-color: #2c5a7b;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card {
  background-color: white;
  padding: 2rem 3.5rem;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 7rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card h1 {
  margin-bottom: 5px;
}

.card div {
  margin: 30px 0;
}
