.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  width: 100%;
}

.floatContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  width: 100%;
  position: absolute;
  top: 25px;
  z-index: 4;
}

.container a {
  text-decoration: none !important;
}

.btn {
  background-color: #e96a34;
  text-align: center;
  color: white;
  border-radius: 15px;
  padding: 11px 20px;
  margin-left: 10px;
  font-size: 0.8rem;
  font-weight: 500;
  width: auto;
  height: auto;
  border: none;
  cursor: pointer;
}
