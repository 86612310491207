.data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}

.dataMobile {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 5px;
}

.reviewData {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: center;
  }