.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin-right: 10px;
}

.iconsMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  min-width: 20vw;
  margin-right: 10px;
  width: auto;
  text-align: center;
}

.icons div {
  flex-wrap: wrap;
  text-align: center;
  margin-top: 5px;
}
