.header {
  left: 0;
  top: 0;
  height: 10vh;
  width: 100vw;
  background-color: #2c5a7b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn {
  cursor: pointer;
  margin-left: 20px;
  color: white;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 30pt 0;
  gap: 30px;
}

.review {
  border: 1px black solid;
  width: 50%;
  border-radius: 15px;
}
