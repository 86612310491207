.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.isWaitlist {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  font-style: italic;
}

.section {
  width: 100%;
  margin-bottom: 2rem;
}

.reviewSection {
  width: 100%;
  margin-bottom: 6rem;
  margin-top: 1.5rem;
}

.bold {
  font-family: Avenir;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  color: #303030;
}

.btnS {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.leftRight {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.question {
  text-align: left;
  width: 100%;
  margin-top: 10px;
}

.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formMobile {
  font-family: Avenir;
  font-family: sans-serif;
  font-size: 14px;
  font-style: normal;
  color: #303030;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  text-align: center;
}
.formMobile span {
  margin-bottom: 0;
}
.formMobile input {
  margin-bottom: 0;
  margin-top: 5px;
  padding: 5px;
  width: 90%;
  font-style: normal;
  background-color: #fbfafa;
  border: 1px solid #ccc;
  font-size: 0.85rem;
}
.formMobile textarea {
  min-height: 10vh;
  margin-top: 3px;
  margin-bottom: 5px;
  width: 95%;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fbfafa;
  font-style: normal;
  font-size: 0.85rem;
}
.formMobile select {
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fbfafa;
  padding: 5px 10px;
  text-align: center;
  margin-top: 3px;
  font-style: normal;
}

.form {
  font-family: Avenir;
  font-family: sans-serif;
  font-style: normal;
  color: #303030;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.form input {
  margin-bottom: 10px;
  width: 90%;
  font-style: normal;
  background-color: #fbfafa;
  border: 1px solid #ccc;
  font-size: 0.85rem;
}
.form textarea {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 95%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fbfafa;
  font-style: normal;
  font-size: 0.85rem;
}
.form select {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fbfafa;
  padding: 12px 20px;
  width: 200px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-style: normal;
  font-size: 0.85rem;
}
