.container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftContainer {
  background-color: white;
  height: 100%;
  width: 360pt;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.btn {
  z-index: 1;
  border: 0;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  width: 23px;
  height: 48px;
  cursor: pointer;
  border-left: 1px solid rgb(218, 220, 224);
  border-radius: 0 8px 8px 0;
  background: #fff 7px center/7px 10px no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-2px);
}

.reviewData {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: center;
}

.img {
  width: 100%;
}

.imgR {
  width: 100%;
  transform: rotate(180deg);
}

.dormPic {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 200px;
}

.info {
  width: 90%;
  padding-top: 20px;
}

.info a {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
}

.sum {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* .sum div {
  margin-bottom: 5px;
} */

.data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.text {
  margin: 20px 0 25px 0;
}
.text div {
  margin-top: 10px;
}
.text a {
  text-decoration: underline;
}

.link {
  text-decoration: underline;
  margin-top: 10px;
  color: rgb(105, 118, 128);
  font-size: 12px;
}