.draggableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

div {
  font-family: Avenir;
  font-family: sans-serif;
  font-style: normal;
}

.bottomSheet {
  box-shadow: 0px 3px 8px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  background: white;
  width: 100%;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  z-index: 40;
}
