.container {
  margin-bottom: 25px;
  font-size: 16px;
}

.header1 {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-top: 15px;
  align-items: center;
}

.header1Mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.ratingYear {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.ratingYearMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 10px 0 5px 0;
}

.message {
  margin-bottom: 25px;
}

.schoolYear {
  margin-left: auto;
  margin-right: 0;
  width: auto;
  background-color: #2c5a7b;
  color: white;
  padding: 7px 13px;
  border-radius: 25px;
  font-weight: 50;
  font-size: 15px;
  text-transform: capitalize;
}

.schoolYearMobile {
  background-color: #2c5a7b;
  color: white;
  padding: 5px 7px;
  border-radius: 25px;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
}
