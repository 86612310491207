.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 20px;
  max-width: 100%;
  flex-wrap: wrap;
}

.container img {
  height: 40px;
  margin-top: 3px;
  flex-wrap: wrap;
}

.containerMobile {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  flex-wrap: wrap;
}

.containerMobile img {
  height: 25px;
  margin-top: 3px;
}

.bold {
  font-family: Avenir;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  color: #303030;
}

.proMobile {
  margin-right: 20px;
  margin-bottom: 15px;
}

.pro {
  margin-right: 20px;
  margin-bottom: 10px;
}

.subSections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.subSectionsMobile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.iconsMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin-right: 10px;
  text-align: center;
}

.iconsMobile div {
  flex-wrap: wrap;
  text-align: center;
  margin-top: 5px;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  margin-right: 10px;
}

.icons div {
  flex-wrap: wrap;
  text-align: center;
  margin-top: 5px;
}